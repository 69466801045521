import React from 'react';
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Products from './Pages/Products';
import Services from './Pages/Services';
import Contact from './Pages/Contact';

export function Router() {
  return (
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/about/" element={<About/>} />
      <Route path="/products/" element={<Products/>} />
      <Route path="/services/" element={<Services/>} />
      <Route path="/contact/" element={<Contact/>} />
    </Routes>
  );
}